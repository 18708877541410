var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"align-self-start"},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('BaseCard',{attrs:{"heading":"Historial de Transacciones"}},[_c('div',{staticClass:"mt-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.histTransacciones,"item-key":"id","sort-by":"fecha","sort-desc":true,"search":_vm.search,"items-per-page":10,"footer-props":{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right'
              },"custom-sort":_vm.customSort,"loading":_vm.loading,"loading-text":"Cargando... Un momento por favor"},scopedSlots:_vm._u([{key:"item.status",fn:function({ item }){return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"pb-5",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-1",attrs:{"fab":"","dark":"","small":"","color":"success","title":"Refrescar"},on:{"click":_vm.getTransactions}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-refresh")])],1)],1)]},proxy:true}])})],1)])],1)],1),_c('sesionExpiredDialog',{attrs:{"dialog":_vm.dialog,"persistent":"","transition":"dialog-bottom-transition","max-width":"320"},on:{"update:dialog":function($event){_vm.dialog=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }