<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-row class="align-self-start">
      <v-col cols="12" lg="12">
        <BaseCard heading="Historial de Transacciones">
            <!-- ----------------------------------------------------------------------------- -->
            <!-- TableSimpleFixHeader -->
            <!-- ----------------------------------------------------------------------------- -->
            <!-- <v-list-item-subtitle class="text-wrap">
            Use the <code>fixed-header</code> prop together with the <code>height</code> prop to fix the header to the top of the table.
            </v-list-item-subtitle> -->
            <div class="mt-0">       
            <v-data-table
                :headers="headers"
                :items="histTransacciones"
                item-key="id"
                sort-by="fecha"
                :sort-desc="true"
                :search="search"
                :items-per-page="10"
                :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right'
                }"
                :custom-sort="customSort"
                :loading="loading"
                loading-text="Cargando... Un momento por favor"
            >
                <!-- This template looks for headers with formatters and executes them -->
                <!-- <template
                v-for="header in headers.filter((header) =>
                    header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ header, value }"
                >
                {{ header.formatter(value) }}
                </template> -->
                <template v-slot:item.status="{ item }">
                    <v-chip
                        :color="getColor(item.status)"
                        dark
                    >
                        {{ item.status }}
                    </v-chip>
                </template>
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Buscar"
                            single-line
                            hide-details
                            class="pb-5"
                        ></v-text-field> 
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mb-1"
                            fab
                            dark
                            small
                            color="success"
                            title="Refrescar"
                            @click="getTransactions"
                        >
                            <v-icon dark>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>
                </template>
            </v-data-table>
            </div>
        </BaseCard>
      </v-col>
    </v-row>
    <sesionExpiredDialog
      :dialog.sync="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="320"
    >
    </sesionExpiredDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import sesionExpiredDialog from '@/components/mainComponents/SessionExpiredDialog.vue'

export default {
    name: "Transacciones",

    data: () => ({
        usrToken: '',
        usrData: {},
        loading: true,
        dialog: false,
        search: '',
        histTransacciones: [],
        headers: [
            {
            text: 'Tipo',
            align: 'start',
            sortable: false,
            value: 'tipo',
            },
            { text: 'Valor', value: 'valor' },
            { text: 'Formato', value: 'formato' },
            { text: 'Descripción', value: 'descripcion' },
            { text: 'Fecha', value: 'fecha', /* formatter: (x) => (x ? moment(x).format('DD-MM-YYYY HH:mm') : null) */},
            { text: 'Status', value: 'status' },
        ],
    }),
  
    components: {
      sesionExpiredDialog
    },

    methods: {
        getColor (status) {
            if (status == 'Pendiente') return 'orange'
            else if (status == 'Rechazada') return 'red'
            else if (status == 'Disponible') return 'blue'
            else return 'green'
        },
        customSort: function(items, index, isDesc) {
            console.log('index: ', index, isDesc)
            items.sort((a, b) => {
                ///if (index[0]=='date' || index[0]=='fecha') {
                if(Date.parse(a[index]) || Date.parse(b[index]) ){
                    //console.log('a: ', a[index], new Date(a[index]))
                    //console.log('b: ', b[index], new Date(b[index]))
                    if (isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        }
                        else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        },
        getTransactions() {
            this.loading = true
            //Obtener los datos de las Transacciones
            axios({
                method: 'GET',
                url: `${process.env.VUE_APP_API_URL}/api/users/${this.usrData.id}/transacciones_all`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' +this.usrToken ,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                let respTransaccion = response.data
                //console.log('Transacciones...', respTransaccion);
                if (!respTransaccion.success){
                    console.error('Transacciones Error: ', respTransaccion)
                }
                else {
                    this.histTransacciones = respTransaccion.data.map((trans) => {
                        //console.log(trans.valor, trans.valor.toLocaleString("es-VE", {style:"currency", currency:"USD"}))
                        return {
                            id: trans.nro,
                            tipo: trans.tipo,
                            valor: '$' + Number(trans.valor).toLocaleString("es-VE"),
                            formato: trans.formato,
                            descripcion: trans.descripcion,
                            fecha: moment(trans.fecha).format('YYYY-MM-DD HH:mm'),
                            status: trans.status
                        }
                    })
                }
                
                this.loading = false
            })
            .catch(error => {
                console.error('Transacciones Error: ', error)
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialog = true
                }
            })
        }
    },
  
  async created () {
    this.usrData = await JSON.parse(localStorage.getItem("user"))
    this.usrToken = localStorage.getItem("usrToken")
    this.getTransactions()
  }
};
</script>